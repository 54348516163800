.newPost {
  width: 60vw;
  margin: 4vh auto;
}

.newPost .title {
  margin: 20px 0px;
}
.newPost input,
textarea {
  width: 100%;
}

.newPost .mdView p{
  font-size: 20px;
}

.newPost .topInputs {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-gap: 20px;
}
.newPost .topInputs input {
  width: 100%;
}
