.sidebar {
  position: fixed;
  left: 10px;
  width: 15vw;
  top: 0px;
  height: 100%;
  margin: 10vh 10px;
  text-align: center;
}

.sidebar .links {
  display: grid;
  grid-gap: 20px;
  font-size: 19px;
}

@media only screen and (max-width: 1300px) {
  .sidebar {
    display: none;
  }
  .posts {
    width: 90vw;
    margin: auto;
  }
  .postBox {
    width: 80vw;
  }
}
