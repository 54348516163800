.postTable {
  width: 70vw;
  margin: 3vh 0px;
}

.postTable table {
  width: inherit;
  border-radius: 20px;
  font-size: 18px;
  background-color: rgb(40, 41, 61);
  padding: 20px;
  text-align: center;
}

.light .postTable table {
  box-shadow: 10px 10px 10px var(--light-theme-shadow);
  background-color: white;
}
