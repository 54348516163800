.postDescription {
  width: 50vw;
  margin: 4vh auto;
  padding: 20px;
}

.postDescription .description {
  margin-top: 50px;
  font-size: 20px;
  line-height: 35px;
}
.postDescription img {
  max-height: 90vh;
  max-width: 70vw;
}
.postDescription .actions {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}

.postDescription h1.post-title {
  font-size: 2.9rem;
}
