:root {
  --light-theme-bg: #f8f7f3;
  --light-theme-shadow: rgb(241, 241, 241);
  --primary-color: rgb(62, 123, 250);
  --red: rgb(255, 92, 92);
  --green: rgb(57, 217, 138);
  --yellow: rgb(253, 221, 72);
  --purple: rgb(172, 93, 217);
  --background-color: rgb(30, 30, 39);
  --pink: rgb(217, 68, 150);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Raleway", sans-serif;
  background-color: var(--background-color);
  color: white;
}

.container {
  width: 60vw;
  margin: 4vh auto;
}

button {
  background-color: var(--primary-color);
  padding: 10px;
  border-radius: 10px;
}

.light {
  color: #353e4b;
  background-color: var(--light-theme-bg);
}
.light a {
  color: black;
}
/* .light .box {
  box-shadow: 0px 0px 10px 10px var(--light-theme-shadow);
  background-color: white;
} */
.light blockquote {
  box-shadow: 10px 10px 10px var(--light-theme-shadow);
}
code {
  font-family: "Fira Mono", monospace;
  background-color: rgb(28, 32, 44);
  padding: 8px;
  color: white;
  border-radius: 10px;
  margin-top: 10vh;
}

pre {
  margin: 40px 0px;
}

input,
textarea {
  border: none;
  font-size: 25px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  height: 5vh;
  color: white;
  background-color: rgb(40, 41, 61);
  font-family: "Raleway", sans-serif;
  padding: 5px;
}

.light input,
.light textarea {
  border: 1px solid var(--primary-color);
  font-size: 25px;
  height: 5vh;
  color: rgb(24, 24, 31);
  background-color: white;
  font-family: "Raleway", sans-serif;
  padding: 5px;
  box-shadow: inset 2px 2px 3px #eee;
}

button {
  border: none;
  padding: 10px 20px;
  background: var(--primary-color);
  color: white;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  cursor: pointer;
  margin: 0px 0px;
}

button.primary {
  background: var(--primary-color);
}
button.dark {
  background: var(--background-color);
}
button.red {
  background: var(--red);
}
button.yellow {
  background: var(--yellow);
}
button.pink {
  background-color: var(--pink);
}

input:focus,
textarea:focus {
  outline: none;
}

a {
  color: white;
}

a.title {
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #222831;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #353e4b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #151a22;
}
blockquote {
  border-left: 5px solid #353e4b;
  box-shadow: 10px 10px 30px #191b1f;
  padding: 10px;
  margin: 4vh 0px;
}
