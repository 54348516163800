.portfolio-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: calc(
    100% - 40px
  ); /* Adjust the width based on the number of items per row */
  max-width: 400px; /* Set a maximum width to maintain responsiveness */
  border: 2px solid #be48a4;
  border-radius: 10px;
  margin: 20px 10px; /* Adjust the margin as needed */
  box-sizing: border-box; /* Include border in the width calculation */
}

@media (max-width: 767px) {
  .portfolio-item {
    width: 100%;
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 80%; /* Adjust the aspect ratio as needed */
  overflow: hidden;
  border: 2px solid #00ffff;
  border-radius: 10px;
}

.portfolio-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(190, 72, 164, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .image-overlay {
  opacity: 1;
}

.overlay-options {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.overlay-option {
  color: #00ffff;
  text-decoration: none;
  font-weight: bold;
  padding: 5px 10px;
  border: 2px solid #00ffff;
  background-color: rgba(190, 72, 164, 0.8);
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.overlay-option:hover {
  background-color: #be48a4;
  color: #fff;
}

.portfolio-title {
  font-size: 20px;
  margin: 10px 0;
  color: #00ffff;
}

.portfolio-description {
  color: #fff;
}

.portfolio-date {
  color: #00ffff;
  font-style: italic;
}
