.tags-post-list {
  padding: 30px;
}

.tags-post-list .posts-links {
  margin-top: 5rem;
}

.tags-post-list h1 {
  font-size: 5rem;
  color: var(--pink);
}

.tags-post-list .taggedPost {
  margin: 1.3rem 0px;
  font-size: 1.3rem;
}

.tags-post-list .taggetPost a.tagged-title {
  text-decoration: underline;
}
