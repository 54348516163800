.aboutBox .links {
  display: flex;
  margin-top: 1rem;
}

.aboutBox .links a {
  margin-right: 10px;
}
.shiny-button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #2c2c54;
  border: none;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}

.shiny-button::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 500%;
  background: linear-gradient(
    45deg,
    #fdcb6e,
    #e17055,
    #d63031,
    #e84393,
    #0984e3,
    #6c5ce7,
    #fd79a8
  );
  opacity: 0.6;
  transform: rotate(45deg);
  animation: shine 4s infinite;
  z-index: -1;
}

@keyframes shine {
  0% {
    transform: rotate(45deg) translateX(-150%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(45deg) translateX(150%);
    opacity: 0;
  }
}
