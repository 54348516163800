.portfolio {
  width: 60vw;
  margin: auto;
}

.portfolio .portfolio-content {
  padding: 20px 30px;
}

.portfolio .portfolio-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.portfolio .portfolio-item-wrapper {
  width: calc(100% / 3 - 10px); /* Adjust the width and spacing as needed */
  margin-bottom: 20px; /* Adjust the margin as needed */
}

@media (max-width: 767px) {
  .portfolio .portfolio-item-wrapper {
    width: 100%;
  }
}
