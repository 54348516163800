.posts .postList {
  height: 100vh;
  margin-top: 1rem;
}
.posts .topHeader {
  position: fixed;
  top: 0px;
  background-color: rgb(28, 28, 40);
  width: 100%;
  height: 60px;
  padding: 10px;
}
.light .posts .topHeader {
  background-color: var(--light-theme-bg);
}
