.success {
  background-color: var(--green);
}
.danger {
  background-color: var(--red);
}
.alert {
  font-size: 20px;
  padding: 20px;
  border-radius: 10px;
  color: white;
  margin: 20px;
}
