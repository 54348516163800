.loader {
  height: 4vh;
  width: 4vh;
  border-radius: 100%;
  border: 3px solid rgb(243, 239, 248);
  border-top-color: rgb(26, 27, 39);
  animation: load 0.4s linear infinite;
  margin: auto;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
