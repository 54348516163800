.aboutMe {
  text-align: center;
  width: inherit;
  margin: 60px auto;
}

@media only screen and (max-width: 900px) {
  .aboutMe {
    width: 80vw;
  }
}
