.postBox {
  width: 70%;
}

.postBox h1 {
  color: var(--red);
  font-size: 2.8rem;
}

/* .postBox .icons {
  color: var(--primary-color);
} */

.postBox p {
  letter-spacing: 1px;
}
