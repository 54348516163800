.layout {
  width: 60vw;
  margin: auto;
}
.switchThemeButton {
  background: none;
  border: none;
  font-size: 30px;
  position: fixed;
  float: right;
  top: 10px;
  right: 30px;
  z-index: 3;
}
.light .switchThemeButton {
  color: black;
}
